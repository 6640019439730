import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Layout } from '../components/common';
import { ColoredButton, StyledButton } from '../components/common/Buttons';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import { Image } from '../utils/image';

const DivContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const MonthlyButton = styled.button`
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border: solid 0.5px #e6e6e6;
    border-right: none;
    padding: 0.75rem;
    padding-left: 3rem;
    padding-right: 3rem;
    color: #29558c;
    background-color: #fffefe;
    &:hover {
        color: black;
    }
`;

const AnnuallyButton = styled.button`
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border: solid 0.5px transparent;
    border-left: none;
    padding: 0.75rem;
    padding-right: 3rem;
    padding-left: 3rem;
    color: #ffffff;
    background-image: linear-gradient(91deg, #ffa66d 21%, #f8528d 107%);
    &:hover {
        color: black;
    }
`;

const StyledHeaderDiv = styled.div`
    font-weight: bold;
    margin-top: 1rem;
`;

const StyledH5 = styled.h5`
    font-size: 2rem;
    color: #2e598f;
    padding: 0.5rem;
    padding-bottom: 2rem;
`;

const Pricing = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Flexible Pricing For Businesses And Individuals"
                desc="Browse our products for the best suited solution for your business then contact our sales team"
                imageUrl="/images/pricing-hero@3x.png"
            />
            <HeadingSection
                heading="Pricing"
                desc="Sign up in less than 30 seconds. Try out our 7 day risk free trial, upgrade at anytime, 
no questions, no hassle."
            />
            <Row noGutters>
                <Col xs="12">
                    <DivContainer>
                        <MonthlyButton>Monthly</MonthlyButton>
                        <AnnuallyButton>Annually</AnnuallyButton>
                    </DivContainer>
                </Col>
            </Row>
            <Row noGutters style={{ marginTop: `8rem`, marginBottom: `5rem` }}>
                <Col lg="3" md="12">
                    <Card
                        style={{
                            color: `#535b6c`,
                            backgroundColor: `#fff9f2`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                                height: `30rem`,
                            }}
                        >
                            <StyledHeaderDiv>Basics</StyledHeaderDiv>
                            <StyledH5>₦1,500</StyledH5>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <div style={{ marginTop: `5rem` }}>
                                <StyledButton style={{ marginRight: 0 }}>
                                    Sign up Today
                                </StyledButton>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="3" md="12">
                    <Card
                        style={{
                            color: `#535b6c`,
                            backgroundColor: `#fff9f2`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                                height: `30rem`,
                            }}
                        >
                            <StyledHeaderDiv>Small Business</StyledHeaderDiv>
                            <StyledH5>₦2,500</StyledH5>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <div style={{ marginTop: `5rem` }}>
                                <StyledButton style={{ marginRight: 0 }}>
                                    Sign up Today
                                </StyledButton>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="3" md="12">
                    <Card
                        style={{
                            color: `#535b6c`,
                            transform: `scale(1.1)`,
                            zIndex: 1,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                                height: `30rem`,
                            }}
                        >
                            <div>
                                <StyledButton
                                    style={{
                                        fontSize: `.75rem`,
                                        marginRight: 0,
                                    }}
                                >
                                    Recommended
                                </StyledButton>
                            </div>
                            <div style={{ fontWeight: `bold` }}>
                                Professional
                            </div>
                            <StyledH5>₦3,000</StyledH5>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <div style={{ marginTop: `5rem` }}>
                                <ColoredButton style={{ marginRight: 0 }}>
                                    Sign up Today
                                </ColoredButton>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="3" md="12">
                    <Card
                        style={{
                            color: `#535b6c`,
                            backgroundColor: `#fff9f2`,
                        }}
                    >
                        <Card.Body
                            style={{
                                textAlign: `center`,
                                height: `30rem`,
                            }}
                        >
                            <StyledHeaderDiv>Enterprise</StyledHeaderDiv>
                            <StyledH5>₦2,000</StyledH5>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <p>StackIAM is a cloud SASS </p>
                            <div style={{ marginTop: `5rem` }}>
                                <StyledButton style={{ marginRight: 0 }}>
                                    Sign up Today
                                </StyledButton>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row noGutters>
                <Col lg="6" md="12">
                    <div>
                        <Image
                            style={{ height: `5rem`, width: `5rem` }}
                            src="/images/pricing-user-account@3x.png"
                        />
                    </div>
                    <div
                        style={{
                            fontSize: `2rem`,
                            paddingBottom: `1rem`,
                            color: `#2e598f`,
                        }}
                    >
                        Users (Everyday People)
                    </div>
                    <ul style={{ color: `#535b6c`, paddingRight: `1rem` }}>
                        <li>
                            Help reduce the drudgery of filling endless forms to
                            register for every new service or product. Aggregate
                            and verify your personal data once and securely.
                        </li>
                        <li>
                            Provide centralised management of personal
                            information and documents.
                        </li>
                        <li>
                            Provides access control, revoke, permission on
                            document assessments and information sharing.
                        </li>
                        <li>
                            Reduce the amount of forms to fill for endless
                            applications and provide api interoperability of
                            data between user and company requiring data.
                        </li>
                        <li>
                            Verify and independently validates data on platform
                        </li>
                        <li>
                            Allows easy application and registration to Business
                            services , embassy, sports etc.
                        </li>
                        <li>
                            Provide convenient Single-sign in integration with
                            multiple users apps.
                        </li>
                        <li>
                            If unverified, you lose access to many features.
                        </li>
                    </ul>
                </Col>
                <Col lg="6" md="12">
                    <div>
                        <Image
                            style={{ height: `5rem`, width: `5rem` }}
                            src="/images/pricing-organization@3x.png"
                        />
                    </div>
                    <div
                        style={{
                            fontSize: `2rem`,
                            paddingBottom: `1rem`,
                            color: `#2e598f`,
                        }}
                    >
                        Business /Corporates
                    </div>
                    <ul style={{ color: `#535b6c`, paddingRight: `1rem` }}>
                        <li>
                            Businesses (Banks, Corporates) - provides single
                            sign on and easy login and registration for
                            services.
                        </li>
                        <li>
                            Integrates with corporate and provides a seamless
                            way to define data needed for profile creation on
                            platforms. Allows users created via the platform to
                            be able to login thorough single sign on
                        </li>
                        <li>
                            WorkForce / Employee Identity management - Useful
                            Unified view to view all your employees and assign
                            them into different groups based on different
                            departments and permission to different resources.
                            Configuration will be available via api for custom
                            internal workflow applications.
                        </li>
                        <li>
                            Customer Identity Management - Sign in/ sign up
                            workflow for your customers. Unified view of
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </Layout>
);

export default Pricing;
